import Link from 'next/link';
import { FaFacebookSquare, FaWhatsapp, FaTwitter, FaInstagram} from "react-icons/fa";


const Footer = ()=>{
    return (
        <footer>
            <div className="container footer">
                <ul className="footerColumn">
                    <li className="footerTitle">Necesitas ayuda?</li>
                    <li>
                        <Link href="/contacto">
                            <a>Centro de contacto</a>
                        </Link>
                    </li>
                    <li>
                        <Link href="https://wa.me/525580980257">
                            <a target="_blank" rel="nofollow noopener noreferrer">Whatsapp</a>
                        </Link>
                    </li>
                    {/*<li>*/}
                    {/*    <Link href="/">*/}
                    {/*        <a>Envio personalizado</a>*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                </ul>
                <ul className="footerColumn">
                    <li className="footerTitle">Conocenos</li>
                    <li>
                        <Link href="/nosotros">
                            <a>Acerca de nosotros</a>
                        </Link>
                    </li>
                    <li>
                        <Link href="/preguntas-frecuentes">
                            <a>Preguntas frecuentes</a>
                        </Link>
                    </li>
                    <li>
                        <Link href="/blog">
                            <a>Blog</a>
                        </Link>
                    </li>
                    {/*<li>*/}
                    {/*    <Link href="/">*/}
                    {/*        <a>Comparte tu experiencia</a>*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <Link href="/">*/}
                    {/*        <a>Servicio empresarial</a>*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                </ul>
                <ul className="footerColumn">
                    <li className="footerTitle">Información</li>
                    <li>
                        <Link href="/aviso-privacidad">
                            <a>Aviso de privacidad</a>
                        </Link>
                    </li>
                    <li>
                        <Link href="/terminos-condiciones">
                            <a>Terminos y condiciones</a>
                        </Link>
                    </li>
                    <li>
                        <Link href="/politica-envio">
                            <a>Politica de envio</a>
                        </Link>
                    </li>
                    {/*<li>*/}
                    {/*    <Link href="/">*/}
                    {/*        <a>Formas de pago</a>*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <Link href="/">*/}
                    {/*        <a>Solicita tu factura</a>*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                </ul>
            </div>
            <ul className="container footerSocial">
                <li>
                    <a href="https://wa.me/525580980257" target="_blank" rel="nofollow noopener noreferrer" className="ws">
                        <FaWhatsapp/>
                    </a>
                </li>
                <li>
                    <a href="https://www.facebook.com/FloreriaDetallesyCaprichos" target="_blank" rel="nofollow noopener noreferrer" className="fb">
                        <FaFacebookSquare/>
                    </a>
                </li>
                <li>
                    <a href="https://twitter.com/floreriadyc" target="_blank" rel="nofollow noopener noreferrer" className="tw">
                        <FaTwitter/>
                    </a>
                </li>
                <li>
                    <a href="https://www.instagram.com/floreriadetallesycaprichos" target="_blank" rel="nofollow noopener noreferrer" className="in">
                        <FaInstagram/>
                    </a>
                </li>
            </ul>
            <div className="footerCopy">
                &copy; {new Date().getFullYear()} Florería Detalles y Caprichos S.A. de C.V.
            </div>
        </footer>
    )
};

export default Footer;
